import { Tabs } from "@geist-ui/core";
import PageTitle from "../components/PageTitle";
import { useStore } from "../store";
import { API } from "../api";

function PlanPanel(props) {
  return (
    <Tabs.Item label={props.name} value={props.tabId}>
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <stripe-buy-button
          client-reference-id={props.publicHash}
          buy-button-id={props.buttonId}
          publishable-key={props.stripeKey}
        ></stripe-buy-button>
      </div>
    </Tabs.Item>
  );
}

export default function Upgrade() {
  const public_hash = useStore((state) => state.public_hash);
  const plans = [
    {
      name: "Pay Monthly",
      buttonId: API.isStripeTest()
        ? "buy_btn_1OhBqNCDbNc3LJFA2ZP9Ahc1"
        : "buy_btn_1OhGefCDbNc3LJFAY4tOiVPG",
    },
    {
      name: "Pay Yearly (15% cheaper)",
      buttonId: API.isStripeTest()
        ? "buy_btn_1OhBrNCDbNc3LJFAjlgdew7J"
        : "buy_btn_1OhGehCDbNc3LJFAPNdMD190",
    },
    {
      name: "Lifetime (one-off payment)",
      buttonId: API.isStripeTest()
        ? "buy_btn_1OhBsDCDbNc3LJFAqPRe4lkT"
        : "buy_btn_1OhGekCDbNc3LJFAiKy8iRAO",
    },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <PageTitle
        title="Professional Dashboard"
        sub="Unlock your analytics and get unlimited access to all reports and data" //"A fully-featured unlimited data-crunching powerhouse is waiting for you."
      />

      <div
        style={{
          maxWidth: 620,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 30,
          backgroundColor: "#fff",
          padding: 20,
          borderRadius: 10,
          boxShadow: "3px 3px 8px #ddd",
        }}
      >
        {" "}
        We only have two types of accounts:{" "}
        <strong>
          <span style={{ color: "#5e6d85" }}>BASIC</span>
        </strong>{" "}
        and{" "}
        <strong>
          <span style={{ color: "#cc0000" }}>PRO</span>
        </strong>
        <div style={{ color: "#777", fontSize: 13, marginTop: 10 }}>
          On BASIC accounts, we have to limit the amount of data we store and
          put into our reports.
          <br />
          On our PRO accounts, all limits are removed and you can access all
          your data. Simple as that.
        </div>
      </div>

      <div
        style={{
          maxWidth: 600,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Tabs initialValue={0} align={"center"} hideDivider>
          {plans.map((plan, i) => {
            return (
              <PlanPanel
                key={i}
                tabId={i}
                name={plan.name}
                publicHash={public_hash}
                buttonId={plan.buttonId}
                stripeKey={API.getStripeKey()}
              />
            );
          })}
        </Tabs>
      </div>
      <div
        style={{
          marginTop: 15,
          fontSize: 12,
          color: "#999",
          maxWidth: 600,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 50,
        }}
      >
        Please make sure you read our{" "}
        <a href="/refunds">refunds and returns policy</a> before making a
        purchase.
        <br />
        If you are unsure, or just want to try out the service, we highly
        recommend to use the monthly plan. Due to the strongly discounted plans
        and cost involved on our end, there are no refunds on the yearly or
        lifetime plans, except on a very limited case-by-case basis.
      </div>
    </div>
  );
}
