import { Button } from "@geist-ui/core";
import PageTitle from "../components/PageTitle";

export default function UpgradePaid() {
  return (
    <div
      style={{
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 540,
        borderRadius: 10,
        padding: 30,
        boxShadow: "2px 2px 4px #00000022",
      }}
    >
      <PageTitle title="Thank you!" sub="Welcome to the Professional Tier" />

      <div>
        It may take a bit for your reports to refresh with fully unlocked data.
        We have triggered the process in the background, so our crawlers will
        work through them with the highest priority to get you started with
        fully unlocked data reports.
        <br />
        <br />
        If there's ever any problem, or you have any questions, please don't
        hesitate to reach out. The quickest way to reach us is through the Chat
        Widget at the bottom right. You can always send us a quick e-mail at:{" "}
        <a href="mailto:support@metricdon.com">support@metricdon.com</a>
        <br />
        <br />
        If you need to cancel, reach out anytime (or use the account page to do
        that). We won't ask questions, our only concern is to get your request
        resolved as quickly as possible.
        <br />
        <br />
        <Button
          onClick={() => {
            document.location.href = "/?";
          }}
        >
          Continue to Dashboard
        </Button>
      </div>
    </div>
  );
}
