import { Card } from "@geist-ui/core";
import PageTitle from "../components/PageTitle";
import SettingRow from "../components/SettingRow";

export default function SettingsPage() {
  return (
    <>
      <PageTitle title="Preferences" sub="Fine-tune your analytics settings" />

      <h3>Backup and Export</h3>
      <Card>
        <SettingRow
          flag={"b_xls_export_followers"}
          title={
            <>
              Generate a nightly export (Excel){" "}
              <span style={{ marginLeft: 15, color: "#666", fontSize: 12 }}>
                <sup>*</sup>COMING SOON
              </span>
            </>
          }
        />
      </Card>

      <br />

      <h3>Data and Privacy</h3>
      <Card>
        I want to <a href="/goodbye">remove my account.</a>
      </Card>
    </>
  );
}
