import { Display, useMediaQuery } from "@geist-ui/core"

export default function Toot(props) {
  const isXS = useMediaQuery('xs')

  if (!props.toot) {
    return <>
    No toot data.
    </>
  }

  const TootInner = (props) => {
    return (<div style={{fontSize: 14, padding: 15}} dangerouslySetInnerHTML={{__html: props.toot.content}}></div>)
  }

  if (props.raw) {
    return <TootInner toot={props.toot}/>
  }

  return (
    <div>
      <Display width="100%" shadow marginTop={isXS ? 0.5 : undefined} marginBottom={isXS ? -1 : undefined}>
        <TootInner toot={props.toot}/>
      </Display>
    </div>
  )
}