import { Page, useMediaQuery } from '@geist-ui/core';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { API } from '../api';
import { useStore } from '../store';
import { formatDistance, fromUnixTime } from 'date-fns'
import Navigation from './Navigation';
import Loading from './Loading';
import jwt_decode from 'jwt-decode'
import Messages from './Messages';

export default function PageContainer() {

  const isXS = useMediaQuery('xs')

  const [cookies,] = useCookies(['metricdon-token']);
  const user = useStore((store) => store.user)
  const setPublicHash = useStore((store) => store.setPublicHash)
  const setJWT = useStore((store) => store.setJWT);
  const public_hash = useStore((store) => store.public_hash)
  const meta = useStore((store) => store.meta)
  const applyReportData = useStore((store) => store.applyReportData)
  const navigate = useNavigate()
  const [stillLoading, setLoading] = useState(true)
  let location = useLocation()

  useEffect(() => {
    var jwtFound = false
    if (cookies) {
      if (cookies["metricdon-token"]) {
        if (cookies["metricdon-token"].length > 0 && cookies["metricdon-token"][0] === "e") {
          jwtFound = true
          let data = jwt_decode(cookies["metricdon-token"]);
          if (data["public_hash"]) {
            setPublicHash(data["public_hash"])
            setJWT(cookies['metricdon-token'])
          }
        }
      }
    }
    if (!jwtFound) {
      if (location.pathname.indexOf("authorize") < 0) {
        console.log("No token found, let's get back to the login.")
        navigate("/authorize")
      } else {
        setLoading(false)
        console.log("No token, but already on authorization page.");
      }
    }
  }, [cookies, setPublicHash, setJWT, location.pathname, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await API.getReport();
        setLoading(false)
        if (data) {
          applyReportData(data)
        } else {
          if (location.pathname.indexOf("status/wait") < 0) {
            console.log("Invalid data received, maybe no report available yet...")
            navigate("/status/wait");
          }
        }
      } catch (error) {
        setLoading(false)
        if (location.pathname.indexOf("status/wait") < 0) {
          console.log("No report available yet...");
          navigate("/status/wait");
        }
      }
    }
    if (user === null && public_hash !== null) {
      console.log("[PAGE] User empty, fetching report...")
      fetchData().catch(console.error);
    }
  }, [user, applyReportData, location.pathname, navigate, public_hash])

  if (stillLoading) {
    return <Loading centered />
  }

  // Some pages don't require a menu... so hide this
  var showMenu = true;
  if (location.pathname.indexOf("status/wait") >= 0) {
    showMenu = false;
  }

  if (location.pathname.indexOf("/authorize") === 0) {
    return (<>
      <div style={{ backgroundColor: "black", color: "#ddd", fontSize: 20, background: "linear-gradient(152deg, rgba(2,6,22,1) 0%, rgba(59,59,59,1) 100%)" }}>
        <Outlet />
      </div>
    </>)
  }

  return (
    <>
        <Navigation showMenu={showMenu} />
        <Messages />
        <Page width={isXS ? '100%' : undefined} style={{marginTop: isXS ? 100 : undefined}}>
          <Page.Content paddingTop={isXS ? 0 : undefined}>
            <Outlet />
          </Page.Content>
          <Page.Footer style={{width: isXS ? "auto" : undefined, boxSizing: "content-box", position: isXS ? "relative" : "absolute", marginLeft: "auto", marginRight: "auto"}}>
            <div style={{ fontSize: isXS ? 10 : 11, color: "#777", paddingTop:50}}>
              
              &copy; metricdon.com &mdash; { isXS ? "" : "This report was generated"} <span>{(meta !== null ? formatDistance(fromUnixTime(meta.created_at), new Date(), { addSuffix: true }) : "Loading...")}</span>
            </div>
            <br />
          </Page.Footer>
        </Page>
    </>
  )
}