import {
  Avatar,
  Capacity,
  Table,
  Tabs,
  Tooltip,
  useMediaQuery,
} from "@geist-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import Advice from "../components/Advice";
import BetterOnDesktop from "../components/BetterOnDesktop";
import PageTitle from "../components/PageTitle";
import { useStore } from "../store";
import FreePlanNotice from "../components/FreePlanNotice";

function RenderUserTable({ data, explanation, advice }) {
  const isXS = useMediaQuery("xs");

  const processedData = data.map((d) => {
    return {
      avatar: <Avatar src={d.avatar} />,
      username: (
        <Tooltip
          leaveDelay={20}
          placement={"right"}
          enterDelay={0}
          text={d.display_name}
        >
          {d.username}
        </Tooltip>
      ),
      followers_count: d.followers_count.toLocaleString(),
      following_count: d.following_count.toLocaleString(),
      statuses_count: d.statuses_count.toLocaleString(),
      view_url: (
        <a href={d.url} rel="noreferrer" target="_blank">
          View Profile
        </a>
      ),
      score: <Capacity value={d.score} limit={10} />,
    };
  });

  return (
    <>
      <Advice explanation={explanation} advice={advice} />
      <div style={{ padding: 20, overflowX: isXS ? "scroll" : "auto" }}>
        <Table data={processedData}>
          <Table.Column prop="avatar" label="" width={50} />
          <Table.Column prop="username" label="Username" />
          <Table.Column prop="followers_count" label="Followers" />
          <Table.Column prop="following_count" label="Follows" />
          <Table.Column prop="statuses_count" label="Toots" />
          <Table.Column prop="score" label="Score" />
          <Table.Column prop="view_url" label="Profile" width={100} />
        </Table>
      </div>
    </>
  );
}

const renderImpact = (data) => {
  data.sort(function (a, b) {
    return b.score - a.score;
  });
  const explanation = (
    <>
      Accounts listed here are ranked by the <i>impact</i> a boost would have to
      your reach. They are basically your "most popular" followers. The good
      news is that users with a lot of followers can have a huge impact on your
      reach, if they decide to boost a post. The downside is that some may have
      a very busy timeline, and it can be hard to get their attention.
    </>
  );
  const advice = (
    <>
      Keep interacting with these accounts by trying to be a useful resource.
      You will have to be quick with your responses, others will crowd the reply
      timeline quickly.
    </>
  );
  return (
    <RenderUserTable data={data} explanation={explanation} advice={advice} />
  );
};

const renderMarketing = (data) => {
  data.sort(function (a, b) {
    return b.score - a.score;
  });
  const explanation = (
    <>
      If a person (or account) follows many other accounts, there's a good
      chance they will not notice your posts on their timeline due to all the
      noise.
      <br />
      <br />A common method is to auto-follow any account they can find or
      interact with, hoping for a follow back. We score these accounts as
      potential marketing accounts.
      <br />
      <br />
      <strong>Note:</strong> These accounts are on a wide spectrum, from
      "following a few more people than usual" to complete mass-spam accounts.
      They can be regular users who are just trying to grow their accounts more
      aggressively, or who care more about posting than reading content on their
      timeline. In rare cases, advanced users trimming down a busy content
      pipeline with aggressive filters will also appear here.
    </>
  );

  const advice = (
    <>
      It may not be very useful to target or interact with these accounts too
      frequently. Depending on the number of accounts they follow, there is a
      slimming chance they will see your reply. If they're following more than
      500 accounts, your chance of being noticed will diminish dramatically.
    </>
  );
  return (
    <RenderUserTable data={data} explanation={explanation} advice={advice} />
  );
};

const renderPowerUsers = (data) => {
  data.sort(function (a, b) {
    return b.score - a.score;
  });
  const explanation = (
    <>
      A power user is someone who is very active on Mastodon, follows a healthy
      amount of people (and therefore has a reasonably clean timeline to read
      through), and has managed to gather and keep a fundamental followership.
    </>
  );

  const advice = (
    <>
      If you are new here, take notice of these accounts and how they use the
      platform. You can learn a lot by how they interact with others, how and
      what they're posting and observing their general behaviour on Mastodon.
    </>
  );
  return (
    <RenderUserTable data={data} explanation={explanation} advice={advice} />
  );
};

const renderEngagedUsers = (data) => {
  data.sort(function (a, b) {
    return b.score - a.score;
  });
  const explanation = (
    <>
      Users on this list are sorted by <i>how engaged</i> they are with you and
      your timeline. Users who are frequently responding to your content are
      listed at the top. Every user on this list has recently responded to one
      of your toots, or has tried to reach out with a direct mention.
    </>
  );
  const advice = (
    <>
      It may be smart to keep an eye on these users and make sure to follow them
      if you aren't already. Keep an eye out for their mentions if you are
      interested in rewarding interaction and are planning to keep your
      notifications timeline busy.
    </>
  );
  return (
    <RenderUserTable data={data} explanation={explanation} advice={advice} />
  );
};

const renderPopularEngagedUsers = (data) => {
  data.sort(function (a, b) {
    return b.score - a.score;
  });
  const explanation = (
    <>
      Similar to your most engaged users, these users here are of particular
      interest if you care about reach. They are ranked on top by having a large
      audience of their own.
    </>
  );
  const advice = (
    <>
      Being noticed by these users and keeping a conversation going by
      interacting frequently will raise your chances of being reblogged and
      transported to a wider audience.
    </>
  );
  return (
    <RenderUserTable data={data} explanation={explanation} advice={advice} />
  );
};

export default function Audience() {
  let location = useLocation();
  let navigate = useNavigate();
  let followers = useStore((store) => store.followers);

  var dataTab = location.pathname.replace("/audience/", "").replace("/", "");

  var engagedUsers = followers.data.frequent_responders.map((e) => {
    return { ...e["account"]["account"], score: e["score"] };
  });
  var popularUsers = followers.data.popular_responders.map((e) => {
    return { ...e["account"]["account"], score: e["score"] };
  });

  // Limit the score to a bandwidth
  var maxScore = 0;
  engagedUsers.forEach((e) => {
    if (e["score"] > maxScore) {
      maxScore = e["score"];
    }
  });
  if (maxScore > 0) {
    engagedUsers = engagedUsers.map((e) => {
      return { ...e, score: Math.round((10 * e["score"]) / maxScore) };
    });
  }
  maxScore = 0;
  popularUsers.forEach((e) => {
    if (e["score"] > maxScore) {
      maxScore = e["score"];
    }
  });
  if (maxScore > 0) {
    popularUsers = popularUsers.map((e) => {
      return { ...e, score: Math.round((10 * e["score"]) / maxScore) };
    });
  }

  return (
    <>
      <PageTitle
        title={"Audience Analysis"}
        sub={"Understand various characteristics of your audience"}
      />

      <BetterOnDesktop />

      <Tabs
        initialValue={location.pathname}
        value={location.pathname}
        onChange={(e) => {
          navigate(e);
        }}
      >
        <Tabs.Item label="Engaged" value={"/audience/engaged"}></Tabs.Item>
        <Tabs.Item
          label="Popular & Engaged"
          value={"/audience/popular"}
        ></Tabs.Item>
        <Tabs.Item label="Impactful" value={"/audience/impact"}></Tabs.Item>
        <Tabs.Item
          label="Power Users"
          value={"/audience/powerusers"}
        ></Tabs.Item>
        <Tabs.Item
          label="Promotional"
          value={"/audience/marketing"}
        ></Tabs.Item>
      </Tabs>

      {dataTab === "impact" && renderImpact(followers.data.impactful)}
      {dataTab === "marketing" && renderMarketing(followers.data.promotional)}
      {dataTab === "powerusers" && renderPowerUsers(followers.data.power_users)}
      {dataTab === "engaged" && renderEngagedUsers(engagedUsers)}
      {dataTab === "popular" && renderPopularEngagedUsers(popularUsers)}

      <FreePlanNotice style={{ marginTop: 20 }} />

      <div
        style={{
          fontSize: 12,
          color: "#ccc",
          marginTop: 20,
          textAlign: "center",
        }}
      >
        Due to Mastodon rate-limits, we may only show a small extract of your
        data at the moment.
      </div>
    </>
  );
}
