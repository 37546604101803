import { createBrowserRouter, RouterProvider } from "react-router-dom";

import PageContainer from "./components/Page";
import PleaseWait from "./pages/PleaseWait";
import Dashboard from "./pages/Dashboard";
import Logout from "./pages/Logout";
import Audience from "./pages/Audience";
import Authorize from "./pages/Authorize";
import Toots from "./pages/Toots";
import SettingsPage from "./pages/Settings";
import AccountRemoval from "./pages/AccountRemoval";
import FreeAccount from "./pages/FreeAccount";
import Upgrade from "./pages/Upgrade";
import UpgradePaid from "./pages/UpgradePaid";
import Subscription from "./pages/Subscription";
import Refunds from "./pages/Refunds";
import Growth from "./pages/Growth";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageContainer />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "growth/*",
        element: <Growth />,
      },
      {
        path: "audience/*",
        element: <Audience />,
      },
      {
        path: "toots/*",
        element: <Toots />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },
      {
        path: "status/wait",
        element: <PleaseWait />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "authorize",
        element: <Authorize />,
      },
      {
        path: "goodbye",
        element: <AccountRemoval />,
      },
      {
        path: "free",
        element: <FreeAccount />,
      },
      {
        path: "upgrade",
        element: <Upgrade />,
      },
      {
        path: "upgrade/paid",
        element: <UpgradePaid />,
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      {
        path: "refunds",
        element: <Refunds />,
      },
    ],
  },
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
