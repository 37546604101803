import { useStore } from "../store";
import { Grid, useMediaQuery } from "@geist-ui/core";
import Toot from "../components/Toot";
import PageTitle from "../components/PageTitle";
import FreePlanNotice from "../components/FreePlanNotice";

export default function Toots() {
  const isXS = useMediaQuery("xs");
  const toots = useStore((store) => store.toots);

  if (toots.data.most_recent.length === 0) {
    return (
      <div>
        <h4>Check back soon</h4>
        You have no recent toots yet (or we haven't been able to retrieve them
        yet).
      </div>
    );
  }

  return (
    <>
      <PageTitle title="Toot Performance" />
      <PageTitle subtitle title="Overview" />

      <Grid.Container gap={6} justify="center">
        <Grid xs={24} md={6} direction={"column"} alignContent={"flex-start"}>
          <strong>Best Performing</strong>
          <Toot toot={toots.data.best_performing_overall} />
        </Grid>
        <Grid xs={24} md={6} direction={"column"} alignContent={"flex-start"}>
          <strong>Most liked</strong>
          <Toot toot={toots.data.best_performing_by_likes} />
        </Grid>
        <Grid xs={24} md={6} direction={"column"} alignContent={"flex-start"}>
          <strong>Most reblogged</strong>
          <Toot toot={toots.data.best_performing_by_reblogs} />
        </Grid>
        <Grid xs={24} md={6} direction={"column"} alignContent={"flex-start"}>
          <strong>Most replies</strong>
          <Toot toot={toots.data.best_performing_by_replies} />
        </Grid>
      </Grid.Container>

      <br />
      <br />
      <PageTitle subtitle title="Your Recent Toots" />

      <FreePlanNotice style={{ marginTop: 20 }} />

      {toots.data.most_recent.map((toot, i) => {
        if (toot.content === "") return null;
        return (
          <div
            key={`toot_${i}`}
            style={{
              display: "flex",
              alignItems: "flex-start",
              paddingBottom: 10,
              marginBottom: 10,
              borderBottom: "1px solid #ddd",
            }}
          >
            <Grid.Container gap={6} justify="center" marginTop="0.1">
              <Grid xs={24} md={12} direction={"column"} alignItems={"center"}>
                <Toot toot={toot} raw={false} />
              </Grid>

              <Grid xs={24} md={12} direction={"column"} alignItems={"center"}>
                <Grid.Container
                  justify="center"
                  marginTop={isXS ? -1.5 : 2}
                  marginBottom={isXS ? 2 : undefined}
                >
                  <Grid
                    xs={8}
                    md={8}
                    direction={"column"}
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 40, fontWeight: "bold" }}>
                      {toot.favourites_count}
                    </div>
                    <div style={{ fontSize: 12, color: "#555" }}>
                      FAVOURITES
                    </div>
                  </Grid>
                  <Grid
                    xs={8}
                    md={8}
                    direction={"column"}
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 40, fontWeight: "bold" }}>
                      {toot.replies_count}
                    </div>
                    <div style={{ fontSize: 12, color: "#555" }}>REPLIES</div>
                  </Grid>
                  <Grid
                    xs={8}
                    md={8}
                    direction={"column"}
                    alignItems={"center"}
                  >
                    <div style={{ fontSize: 40, fontWeight: "bold" }}>
                      {toot.reblogs_count}
                    </div>
                    <div style={{ fontSize: 12, color: "#555" }}>REBLOGS</div>
                  </Grid>
                </Grid.Container>
              </Grid>
            </Grid.Container>
          </div>
        );
      })}
    </>
  );
}
