import { Button, Card } from "@geist-ui/core";
import { useCookies } from "react-cookie";
import PageTitle from "../components/PageTitle";

export default function FreeAccount() {

  const [cookies,] = useCookies(['MastodonDomain']);

  document.cookie = "n0=1; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/;domain=localhost";
  document.cookie = "n0=1; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/;domain=metricdon.com";

  const share = () => {
    if (cookies && cookies["MastodonDomain"]) {
      window.open("https://" + cookies["MastodonDomain"] + "/share?text=If%20you%20are%20curious%20to%20see%20how%20your%20Mastodon%20account%20performs%2C%20you%20should%20check%20out%3A%20https%3A%2F%2Fmetricdon.com", "_blank");
    }
  }

  return (
    <div>        
        <PageTitle title="Thank you!" sub="You are one of the first 100 users to test-drive our service."/>

        <div style={{marginTop: 50, color:"green", fontSize: 14, fontStyle:"italic"}}>TL;DR: You got a <strong>permanent upgrade</strong>, this account remains free for as long as this service exists. Thank you!</div>

        <div style={{width: 750, marginTop: 40}}>
          <Card>
            Thank you for helping us by ignoring some of the rough edges around here.<br/><br/>
            There are bugs, plenty of missing words and images. We believe we can fine-tune this into a great and care-free service for everyone, if we release and listen early. Your thoughts and ideas are always welcome, just use the chat box on the bottom right, or <a href="https://mstdn.social/@metricdon">reach out to us on Mastodon.</a>
          </Card>

          <br/>

          <Card>
            <h4>Want to give a free account to a friend? Be quick.</h4>
            We'll have to stop the free account offer once we're passing our first 100 users. There will be some kind of a free basic version, but the fully unlocked account (like yours) will only be available as a paid plan (or for free if you're one of the first 100 users).
            <br/><br/>
            { cookies && cookies["MastodonDomain"] && 
            <Button auto type="success"  onClick={()=>{ share() }}>Share with a friend</Button>
            }
          </Card>
        </div>
    </div>
  )
}