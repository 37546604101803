export const Helpers = {
  renderUnixDate: (unixDate) => {
    var date = new Date(parseInt(unixDate) * 1000);
    return date.toLocaleDateString();
  },

  growthHistory: (data, endpoint) => {
    // We'll grab 7 days
    if (!data || data === null) {
      return [0, 0, 0, 0, 0, 0, 0];
    }
    var k = data.map((d) => {
      if (endpoint === "followers") {
        return d["followers_count"];
      }
      if (endpoint === "following") {
        return d["following_count"];
      }
      if (endpoint === "statuses") {
        return d["statuses_count"];
      }
      return 0;
    });
    var fillers = [];
    if (k.length < 7) {
      for (var i = 0; i < 7 - k.length; i++) {
        fillers.push(0);
      }
    }
    return k.concat(fillers).reverse();
  },

  growthHistoryFull: (data, endpoint) => {
    // We'll grab all we have and figure out the exact date label along with it
    if (!data || data === null) {
      return [0];
    }
    var k = data.map((d) => {
      if (endpoint === "followers") {
        return {
          label: d["created_at"],
          count: d["followers_count"],
        };
      }
      if (endpoint === "following") {
        return {
          label: d["created_at"],
          count: d["following_count"],
        };
      }
      if (endpoint === "statuses") {
        return {
          label: d["created_at"],
          count: d["statuses_count"],
        };
      }
      return 0;
    });
    return k.reverse();
  },

  parseMessages: (messages) => {
    var parsed = [];
    if (messages && messages.length > 0) {
      messages.forEach((m) => {
        if (m.lm && m.lm === "show_until") {
          var show_until = new Date(parseInt(m.lp) * 1000);
          if (new Date() > show_until) {
            return; // Skip
          }
        }
        if (m.lm && m.lm === "show_after") {
          var show_after = new Date(parseInt(m.lp) * 1000);
          if (new Date() < show_after) {
            return; // Skip
          }
        }

        parsed.push({
          type: m.type === "success" || m.type === "info" ? "success" : m.type,
          message: m.msg,
          context: m.ctx ? m.ctx : "app",
          required_action: m.a ? m.a : "click",
          action_payload: m.ap ? m.ap : "",
          lifetime_mode: m.lm ? m.lm : "anytime",
          lifetime_payload: m.lp ? m.lp : "",
        });
      });
    }
    return parsed;
  },
};
