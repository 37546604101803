import { useEffect } from "react";
import { useCookies } from 'react-cookie';

import Loading from "../components/Loading";

export default function Logout() {

  const [, , removeCookie] = useCookies(['metricdon-token']);

  useEffect(() => {
    removeCookie('metricdon-token', { path: '/', domain: "metricdon.com" });
    removeCookie('metricdon-token', { path: '/', domain: "localhost" });
    document.location.href = 'https://api.metricdon.com/bye';
  }, [removeCookie])

  return (
    <div style={{ textAlign: "center" }}>
      <Loading />
      <br />
      <span style={{ fontSize: 13, color: "#666" }}>
        Signing out
      </span>
    </div>
  )
}