import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../api";
import Loading from "../components/Loading";
import { useStore } from "../store";

export default function PleaseWait() {

  const applyReportData = useStore((store) => store.applyReportData)  
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      API.getReport()
      .then((data) => {
        if (data) {
          console.log("Report received, setting store data.")
          applyReportData(data)
          navigate("/")
        }  
      }).catch((error) => {})
    }, 5000);
    return () => clearInterval(interval);
  }, [applyReportData, navigate]);

  return (
    <div style={{textAlign:"center"}}>
      <Loading/>
      <br/><br/>
      <h4>
      We are importing your data.
      </h4>
      <span style={{fontSize:13}}>This can take some time, depending on the size of your account<br/>and the exhaustion of the instance you are on.</span>
      <br/><br/>
      <div style={{marginTop: 50, border:"1px solid #ddd", background: "#fafafa", padding:15, borderRadius:15, maxWidth: 400, marginLeft:"auto", marginRight: "auto"}}>
        Now would be a <strong>smart move</strong> to bookmark this page ({navigator.platform.indexOf("Mac")>=0 ? "CMD + D" : "CTRL + D" }) to come back later.
        <br/>
        <br/><br/>
        <strong>Okay, I'll wait, but for how long?<br/></strong>
        Smaller accounts should take about one to three minutes to complete processing.
      </div>
    </div>
  )
}