import { Button, Note } from "@geist-ui/core";
import { useEffect } from "react";
import { API } from "../api";
import { Helpers } from "../helpers";
import { useStore } from "../store";

const Message = ({ message }) => {
  return (
    <>
      <Note label="" type={message.type} style={{ display: "inline-block" }}>
        {message.message}
        {message.required_action === "follow_link" && (
          <>
            <Button
              onClick={() => {
                document.location.href = message.action_payload;
              }}
              style={{ marginLeft: 20 }}
              type={message.type}
              auto
              scale={0.35}
            >
              Resolve
            </Button>
          </>
        )}
      </Note>
    </>
  );
};

export default function Messages() {
  const user = useStore((store) => store.user);
  const messages = useStore((store) => store.system_messages);
  const messages_last_updated = useStore(
    (store) => store.messages_last_updated
  );
  const setSystemMessages = useStore((store) => store.setSystemMessages);
  const setSubscription = useStore((store) => store.setSubscription);

  useEffect(() => {
    if (user !== null) {
      var needMessageUpdate = messages_last_updated === null;
      if (messages_last_updated !== null) {
        // Already updated sometime ago? Do we need another update?
        if (
          messages_last_updated.getTime() / 1000 + 600 <
          new Date().getTime() / 1000
        ) {
          needMessageUpdate = true;
        }
      }
      if (needMessageUpdate) {
        API.getAccount()
          .then((data) => {
            setSystemMessages(Helpers.parseMessages(data.messages));
            setSubscription(data.subscription);
          })
          .catch((e) => {
            console.log(
              "[MESSAGES] Error, trying to update messages failed.",
              e
            );
          });
      }
    }
  }, [
    messages_last_updated,
    messages,
    setSystemMessages,
    user,
    setSubscription,
  ]);

  return (
    <div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
      {messages.map((m, i) => {
        return <Message message={m} key={`msg_${i}`} />;
      })}
    </div>
  );
}
