import { useMediaQuery } from "@geist-ui/core";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { ArrowUpCircle, ArrowDownCircle, MinusCircle } from "@geist-ui/icons";

export default function Statistic(props) {
  const isXS = useMediaQuery("xs");

  const getIcon = (h) => {
    if (h.length > 1) {
      if (h[h.length - 1] > h[h.length - 2]) {
        return <ArrowUpCircle color="#00aa00" />;
      }
      if (h[h.length - 1] < h[h.length - 2]) {
        return <ArrowDownCircle color="#aa0000" />;
      }
      if (h[h.length - 1] === h[h.length - 2]) {
        return <MinusCircle color="#dddddd" />;
      }
    }
    return <ArrowUpCircle />;
  };

  let data = props.data || [0, 0, 0, 0, 0, 0, 0];
  let numberValue = props.value || data[data.length - 1];

  return (
    <div
      style={{
        borderRadius: 15,
        boxShadow: "3px 3px 8px #ddd",
        minWidth: 300,
        marginBottom: isXS ? 10 : 80,
        marginLeft: isXS ? "auto" : "default",
        marginRight: isXS ? "auto" : "default",
      }}
    >
      <div
        style={{
          borderRadius: 15,
          padding: "20px 50px",
          minWidth: 250,
          paddingBottom: 15,
          textAlign: "center",
          userSelect: "none",
        }}
      >
        {props.data && (
          <Sparklines data={data} limit={5} width={100} height={20} margin={5}>
            <SparklinesLine />
          </Sparklines>
        )}
        <div>{getIcon(data)}</div>

        <div style={{ fontSize: 30, fontWeight: "bold", userSelect: "none" }}>
          {numberValue}
        </div>
        <div
          style={{
            fontSize: 13,
            fontWeight: "bold",
            letterSpacing: 1.3,
            color: "#888",
            userSelect: "none",
          }}
        >
          {props.label}
        </div>
      </div>
    </div>
  );
}
