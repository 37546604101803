import { Grid, useMediaQuery } from "@geist-ui/core";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../store";
import AvatarMenu from "./AvatarMenu";
import Supporter from "./Supporter";
import MenuIcon from "@geist-ui/icons/menu";
import PlanMenu from "./PlanMenu";

const MenuItem = (props) => {
  const isXS = useMediaQuery("xs");
  let location = useLocation();
  let navigate = useNavigate();

  var isActive = false;
  var isRoot = location.pathname === "" || location.pathname === "/";
  if (isRoot) {
    isActive = props.url === "/";
  } else {
    // Only check if we're a non-root menu item
    if (props.url !== "/") {
      if (location.pathname.indexOf(props.url) >= 0) {
        isActive = true;
      }
      // We may be many levels deep, so let's look at the
      // root item and see if that applies maybe
      if (!isActive) {
        var currentParts = location.pathname.split("/");
        var currentRoot = currentParts[0];
        if (currentRoot === "" && currentParts.length > 1)
          currentRoot = currentParts[1];
        var menuParts = props.url.split("/");
        var menuRoot = menuParts[0];
        if (menuRoot === "" && menuParts.length > 1) menuRoot = menuParts[1];
        if (menuRoot === currentRoot) {
          isActive = true;
        }
      }
    }
  }
  const [isHover, setIsHover] = useState(false);
  const menuItemStyle = {
    color: isHover || isActive ? "#fff" : "#ddd",
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 3,
    fontSize: isXS ? 12 : 15,
    cursor: "pointer",
    borderBottom:
      isHover || isActive ? "1px solid #E00F21" : "1px dashed transparent",
    transition: "0.3s",
    userSelect: "none",
  };

  const menuStyle = { ...menuItemStyle, ...(props.style ? props.style : {}) };

  return (
    <div
      style={menuStyle}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => {
        navigate(props.url);
      }}
    >
      {props.label || ""}
    </div>
  );
};

const Menu = (props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {props.children}
    </div>
  );
};

export default function Navigation(props) {
  const user = useStore((store) => store.user);
  const navigate = useNavigate();
  const isXS = useMediaQuery("xs");
  const [showAvatarDropdownMobile, setAvatarDropdownMobile] = useState(false);

  if (isXS) {
    // MOBILE MENU
    return (
      <div
        style={{
          position: "fixed",
          overflow: "hidden",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "#222",
          zIndex: 1000,
        }}
      >
        <Grid.Container gap={0} justify="center">
          <Grid xs={24}>
            <Supporter />
          </Grid>
          <Grid xs={24}>
            <div
              style={{
                boxSizing: "border-box",
                padding: 3,
                alignItems: "center",
                width: "100%",
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {props.showMenu && (
                  <Menu>
                    <MenuItem
                      style={{ marginLeft: 20 }}
                      label="Dashboard"
                      url={"/"}
                    />
                    <MenuItem label="Growth" url={"/growth"} />
                    <MenuItem label="Audience" url={"/audience/engaged"} />
                    <MenuItem label="Toots" url={"/toots/performance"} />
                    <div style={{ flexGrow: 2 }}></div>
                    <div
                      style={{
                        padding: 20,
                        paddingTop: 20,
                        paddingLeft: 10,
                        marginRight: 20,
                      }}
                      onClick={() => {
                        setAvatarDropdownMobile(!showAvatarDropdownMobile);
                      }}
                    >
                      <MenuIcon color="#ffffff" />
                    </div>
                  </Menu>
                )}
              </div>
              {showAvatarDropdownMobile && (
                <div>
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <img
                      alt="profile"
                      style={{
                        borderRadius: 30,
                        width: 30,
                        height: 30,
                        marginRight: 15,
                      }}
                      src={user.avatar_static}
                    ></img>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <div>
                        <Link style={{ color: "#ffffff" }} to={"/subscription"}>
                          Plan & Payment
                        </Link>
                      </div>
                      <div>
                        <Link style={{ color: "#ffffff" }} to={"/settings"}>
                          Preferences
                        </Link>
                      </div>
                      <div>
                        <Link style={{ color: "#ffffff" }} to={"/logout"}>
                          Log out
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid.Container>
      </div>
    );
  }

  // DESKTOP MENU
  return (
    <div>
      <Grid.Container gap={0} justify="center">
        <Grid xs={24}>
          <Supporter />
        </Grid>
        <Grid xs={24}>
          <div
            style={{
              boxSizing: "border-box",
              backgroundColor: "#222",
              padding: 15,
              paddingLeft: 85,
              paddingRight: 85,
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <div>
                <img
                  alt="logo"
                  src={"/logo.png"}
                  style={{ width: 120, marginTop: 5, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
              <div style={{ flexGrow: 1, marginLeft: 50 }}>
                {props.showMenu && (
                  <Menu>
                    <MenuItem label="Dashboard" url={"/"} />
                    <MenuItem label="Growth" url={"/growth"} />
                    <MenuItem label="Audience" url={"/audience/engaged"} />
                    <MenuItem label="Toots" url={"/toots/performance"} />
                  </Menu>
                )}
              </div>
              <div
                style={{
                  color: "white",
                  width: 200,
                  textAlign: "right",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <PlanMenu />
                <AvatarMenu user={user} />
              </div>
            </div>
          </div>
        </Grid>
      </Grid.Container>
    </div>
  );
}
