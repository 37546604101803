import { Triangle } from "react-loader-spinner"

export default function Loading(props) {
  if (props.centered) {
    return (
      <div style={{width: "100%", textAlign: "center", marginTop:"50vh"}}>
        <Triangle
          height="80"
          width="80"
          color="#FF3D1F"
          ariaLabel="triangle-loading"
          wrapperStyle={{justifyContent: "center"}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    )
  } else {
    return (
      <Triangle
        height={props.size || 40}
        width={props.size || 40}
        color="#FF3D1F"
        ariaLabel="triangle-loading"
        wrapperStyle={{justifyContent: "center"}}
        wrapperClassName=""
        visible={true}
      />
    )
  }

}