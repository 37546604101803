import { Note } from "@geist-ui/core";
import { useStore } from "../store";
import { useState } from "react";

export default function FreePlanNotice(props) {
  const [isHover, setHover] = useState(false);
  const subscription = useStore((state) => state.subscription);
  if (subscription && subscription.plan !== "free") {
    return null; // Pro
  }
  return (
    <div
      style={
        props.style || {
          cursor: "pointer",
          marginBottom: props.paddingBottom ? props.paddingBottom : "5px",
        }
      }
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        document.location.href = "/upgrade";
      }}
    >
      <Note
        type="secondary"
        label={false}
        style={{
          transition: "0.4s",
          backgroundColor: isHover ? "#474d57" : "transparent",
          color: isHover ? "#fafafa" : "#777",
          borderColor: isHover ? "#transparent" : "#c5cfde",
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            ⚠️ &nbsp; This report was generated using the free plan,{" "}
            {props.limitLabel
              ? props.limitLabel
              : "and only shows up to three rows of data."}
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <a
            style={{
              color: isHover ? "#FFD700" : "#FFA500",
              fontWeight: "bold",
              transition: "0.5s",
            }}
            href="/upgrade"
          >
            Show more data
          </a>
        </div>
      </Note>
    </div>
  );
}
