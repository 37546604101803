import { useState } from "react";
import { useMediaQuery } from "@geist-ui/core";
import "./Authorize.css"

export default function Authorize() {

  const isXS = useMediaQuery('xs')

  const [domain, setDomain] = useState("");
  const [t, setT] = useState("");

  const basicCheck = (e) => {
    var preStage = false;
    var domain = document.getElementById('domain').value;
    if (domain.length === 0) preStage = true;
    if (domain.length > 100) preStage = true;
    if (domain.indexOf(".") <= 0) preStage = true;
    if (preStage) {
      document.getElementById("continue").style.display = 'none';
      document.getElementById("help").style.display = 'block';
    } else {
      document.getElementById("continue").style.display = 'block';
      document.getElementById("continue").style.opacity = 1;
      document.getElementById("help").style.display = 'none';
    }
    if (e.keyCode === 13) {
      authorize();
    }
  }

  const authorize = () => {
    var domain = document.getElementById('domain').value.toLowerCase();
    domain = domain.replace(/\s/g, "")
    domain = domain.replace("https://", "");
    domain = domain.replace("http://", "");
    domain = domain.replace("://", "");
    domain = domain.replace("//", "");
    if (domain.indexOf("/") >= 0) {
      domain = domain.split("/")[0];
    }
    domain = domain.replace("/", "");

    // Sometimes users specify their full user@domain account instead of the domain,
    // so let's split this up if needed. Also, we'll need to grab the LAST part here...
    // because users can specify it like this: roman@mstdn.social or @roman@mstdn.social
    if (domain.indexOf("@")>=0) {
      domain = domain.split("@")[domain.split("@").length-1]
    }

    document.cookie = "MastodonDomain=" + domain + "; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/;domain=metricdon.com";
    //document.location.href = 'https://' + domain + '/oauth/authorize?response_type=code&client_id=js8ZRCDuvlLapBY330tdjHcAGwVBEztHr2iXf7ynJ4g&redirect_uri=https://api.metricdon.com/account/authorized&scope=read';    
    document.location.href = `https://api.metricdon.com/system/ensure_client/${domain}`
  }

  if (document.location.search.indexOf("tokenrider") >= 0) {
    return (
      <div>Token Rider: <input type='text' size="64" value={t} onChange={(tt) => { setT(tt.target.value) }} /> <button onClick={() => {
        document.cookie = "metricdon-token=" + t + "; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/;domain=metricdon.com";
        document.cookie = "metricdon-token=" + t + "; expires=Thu, 01 Jan 2030 00:00:00 UTC; path=/;domain=localhost";
        document.location.href = '/?'
      }}>Ride</button></div>
    )
  }

  return (
    <div className="authorize-container">
      <div className="authorize-card" style={{width: isXS ? "90%" : undefined}}>
        <div className="card2"  style={{width: isXS ? "100%" : undefined}}>

          <div className="authorize-inner"  style={{fontSize: isXS ? 16 : undefined, padding: isXS ? 15 : 35}}>

            <img className="icon-pulse" style={{ width: 40, marginBottom: 10, marginTop: 20 }} src="/img/deadbird_small.png" alt="metricdon" /><br />
            <img style={{ width: 90, marginBottom: 40 }} src="/img/metricdon_small.png" alt="metricdon" /><br />

            We need your permission to get <strong>read-only</strong> access to your statistics.<br /><br /><span
              style={{ fontSize: 12, color: "#666" }}>No need to create an extra account with us,<br />your Mastodon account is
              all you need.</span><br />
            <div style={{ borderTop: "1px dashed #333", marginTop: 20, marginBottom: 0 }}></div>
            <br />
            <span style={{ fontSize: 16 }}>
              Type in your Mastodon domain to start
            </span>
            <br /><br />
            <input id="domain" type="text" placeholder="mastodon.social" size="25" value={domain} onChange={(e) => { setDomain(e.target.value) }} onKeyDown={(e) => { basicCheck(e) }} onKeyUp={(e) => { basicCheck(e) }} onMouseDown={(e) => { basicCheck(e) }} /><br />
            <br />
            <br />
            <div id="continue">
              <button className="authorize-button" onClick={() => { authorize() }}>Continue to Dashboard</button>
            </div>
            <div id="help">
              This is the instance you originally signed up with and log in normally. <a href="mailto:office@prototypes.at?subject=metricdon">Reach out</a> if you have any questions.
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}