import { useMediaQuery } from "@geist-ui/core"

export default function PageTitle(props) {
  const isXS = useMediaQuery('xs')
  if (props.subtitle) {
    return (
      <div style={{ marginBottom: isXS ? 20 : 50, marginTop: isXS ? 30 : undefined }}>
        <h3 style={{fontSize: isXS ? 17 : undefined}}>{props.title||""}</h3>
      </div>
    )
  }
  return (
    <div style={{ marginBottom: isXS ? 10: 50, marginTop: isXS ? 0 : undefined }}>
      <h2 style={{fontSize: isXS ? 20 : undefined, marginBottom: isXS ? 0 : undefined,  }}>{props.title||""}</h2>
      <h4 style={{fontSize: isXS ? 12 : undefined}}>{props.sub||""}</h4>
    </div>
  )
}