import { useMediaQuery } from "@geist-ui/core"
import AlertTriangle from '@geist-ui/icons/alertTriangle'

export default function BetterOnDesktop(props) {
  const isXS = useMediaQuery('xs')
  if (!isXS) return (null)
  return (
    <div style={{textAlign: "center", backgroundColor:"#ffeded", padding: 15, borderRadius: 15, fontSize: 14, marginBottom:20, marginTop:20}}>
      <AlertTriangle size="30"/><br/><strong>We are not optimized for mobile, yet.</strong><br/><br/>We recommend you try this on a desktop computer. You will have a much better time working through your data, promised.
    </div>
  )
}