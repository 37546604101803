import { Button } from "@geist-ui/core";
import PageTitle from "../components/PageTitle";
import { useStore } from "../store";
import { useEffect, useState } from "react";
import { API } from "../api";
import Loading from "../components/Loading";
import { Helpers } from "../helpers";

export default function Subscription() {
  const subscription = useStore((state) => state.subscription);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loadingState, setLoadingState] = useState(0);
  const [cancellationInProgress, setCancellationProgress] = useState(0);

  const isLifetime = () => {
    if (
      subscription &&
      subscription.plan &&
      subscription.plan.indexOf("lifetime") >= 0
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      subscription !== null &&
      subscriptionDetails === null &&
      loadingState === 0
    ) {
      setLoadingState(1);
      API.getSubscriptionDetails()
        .then((data) => {
          setSubscriptionDetails(data);
          setLoadingState(2);
        })
        .catch((error) => {
          setLoadingState(3);
        });
    }
  }, [subscription, subscriptionDetails, loadingState]);

  useEffect(() => {
    if (cancellationInProgress === 2) {
      setTimeout(() => {
        document.location.href = "/logout";
      }, 2500);
    }
  }, [cancellationInProgress]);

  if (loadingState === 1) {
    return <Loading />;
  }

  if (cancellationInProgress === 1) {
    return (
      <div style={{ textAlign: "center" }}>
        <Loading />
        <br />
        <br />
        <h3>Cancellation in Progress</h3>
      </div>
    );
  }

  if (cancellationInProgress === 2) {
    return (
      <div style={{ textAlign: "center" }}>
        <h3>Cancellation Completed</h3>
        You will be logged out to make sure the cancellation is confirmed.
        <br />
        <br />
        <Button
          onClick={() => {
            document.location.href = "/logout";
          }}
        >
          Log out
        </Button>
      </div>
    );
  }

  if (isLifetime()) {
    return (
      <>
        <PageTitle
          title="Friends for Life"
          sub="You are on our lifetime plan, you already paid, there's nothing else to do."
        />
        We have sent you a receipt by e-mail (if you haven't received anything
        or lost it, please reach out and we'll send you another one).
        <br />
        <br />
        The idea is that your lifetime plan continues to exist, new features
        will appear over time and the entire world of billing and payments
        should not be a concern to you anymore. You made a smart choice at the
        very beginning, and now it's all sorted. If you still have questions, do
        reach out:{" "}
        <a href="mailto:support@metricdon.com">support@metricdon.com</a>
      </>
    );
  }

  return (
    <>
      <PageTitle
        title="Plan & Payment Details"
        sub="Manage your ongoing subscription and preferences."
      />

      {subscription !== null &&
        subscription.plan &&
        subscription.plan.indexOf("free") >= 0 && (
          <div>
            You currently do not have a valid or ongoing subscription. You can
            upgrade (and cancel) at any time, only takes a click:
            <br />
            <br />
            <Button
              type="success"
              onClick={() => {
                document.location.href = "/upgrade";
              }}
            >
              Upgrade Now
            </Button>
          </div>
        )}
      {subscription !== null &&
        subscription.plan &&
        subscription.plan.indexOf("free") < 0 && (
          <div>
            <h3>Ongoing Subscription</h3>
            Your current billing period started on{" "}
            <strong>
              {Helpers.renderUnixDate(subscription.plan_started)}
            </strong>{" "}
            and renews on{" "}
            <strong>{Helpers.renderUnixDate(subscription.plan_ends)}</strong>.
            <br />
            <br />
            If you cancel before the renewal date, it will automatically stop
            billing you and you will not be charged again.
            <div style={{ marginTop: 20 }}>
              <Button
                type="error"
                ghost
                onClick={() => {
                  let confirm = window.confirm(
                    "Are you sure you want to cancel your subscription? This will be effective immediately and you will receive a basic report on the next report refresh. Confirm to stop the subscription effective immediately."
                  );
                  if (confirm) {
                    setCancellationProgress(1);
                    API.cancelSubscription()
                      .then((result) => {
                        if (result) {
                          setCancellationProgress(2);
                        } else {
                          alert(
                            "There was an error trying to cancel your subscription, please reach out to us so we can take care of this immediately. Sorry!"
                          );
                          setCancellationProgress(0);
                        }
                      })
                      .catch((error) => {
                        alert("There was an error, please try again later.");
                      });
                  }
                }}
              >
                Cancel Subscription
              </Button>
            </div>
            <br />
            <br />
            <h3>Invoices</h3>
            {subscriptionDetails &&
              subscriptionDetails.subscription.invoices &&
              subscriptionDetails.subscription.invoices.length > 0 &&
              subscriptionDetails.subscription.invoices.map((invoice, i) => {
                return (
                  <div key={`invoice_${i}`} style={{ lineHeight: "40px" }}>
                    {Helpers.renderUnixDate(invoice.created_at)}: You{" "}
                    {invoice.is_paid ? "paid" : "have an outstanding payment"} $
                    {parseFloat(invoice.total / 100.0, 2)} for your ongoing PRO
                    plan.
                    {invoice.url && (
                      <a
                        href={invoice.url}
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          marginRight: 10,
                          marginLeft: 10,
                          fontSize: 12,
                          border: "1px solid #ddd",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        Receipt
                      </a>
                    )}
                    {invoice.pdf_url && (
                      <a
                        href={invoice.pdf_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          marginRight: 5,
                          fontSize: 12,
                          border: "1px solid #ddd",
                          borderRadius: 5,
                          padding: 5,
                        }}
                      >
                        Invoice PDF
                      </a>
                    )}
                  </div>
                );
              })}
          </div>
        )}
    </>
  );
}
