import { Display, useMediaQuery } from "@geist-ui/core";
import { Info, X } from "@geist-ui/icons";
import { useState } from "react";
import { useCookies } from 'react-cookie';

export default function Advice(props) {
  const isXS = useMediaQuery('xs')
  const hasher = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)
  const adviceHash = hasher(props.explanation + props.advice)
  const [cookies, setCookie, removeCookie] = useCookies();
  const [minimized, setMinimized] = useState(cookies[`advice-minimized-${adviceHash}`] || false)

  const updateMinimizedState = (willBeMinimized) => {
    // Store in Cookie (if minimized)!
    if (willBeMinimized) {
      setCookie(`advice-minimized-${adviceHash}`, true, { expires: new Date('2030-31-12T00:00:00.000Z') })
    } else {
      removeCookie(`advice-minimized-${adviceHash}`)
    }
    setMinimized(willBeMinimized);
  }

  if (minimized) {
    return (
      <div style={{display:"flex", justifyContent: isXS ? "center" : "flex-end"}}>
        <div onClick={() => { updateMinimizedState(!minimized) }} style={{width: 250, cursor: "pointer", display: "flex", justifyContent: isXS ? "center" : "flex-end"}}>
          <div style={{display:"flex", alignItems: "center", padding:20}}>
            <Info size={20} color="#aaa"/><div style={{marginLeft: 10, fontSize: 14, color: "#777"}}>What does this mean?</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Display shadow>
      <div style={{padding:30}}>
        <div onClick={() => { updateMinimizedState(!minimized) }} style={{cursor: "pointer", float: "right", borderRadius: 30, border: "1px solid #999", width: 30, height: 30, textAlign: "center", boxSizing:"border-box", paddingTop:4, marginTop: isXS ? -15 : 0, marginRight: isXS ? -15 : 0}}>
          <X size={20} color="#999"/>
        </div>
        <div style={{paddingRight:isXS ? 0 : 100}}>
          <div style={{fontWeight:"bold", fontSize: 12, textTransform: "uppercase", color: "#FA3142", letterSpacing: 1.1, marginBottom:10}}>WHAT THIS MEANS</div>
          <div style={{fontSize: isXS ? 13 : 16}}>{props.explanation||""}</div>
          <div style={{fontWeight:"bold", fontSize: 12, textTransform: "uppercase", color: "#FA3142", marginTop: 30, letterSpacing: 1.1, marginBottom:10}}>OUR ADVICE</div>
          <div style={{fontSize: isXS ? 13 : 16}}>{props.advice||""}</div>
        </div>
      </div>
    </Display>
  )

}