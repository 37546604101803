import { Helpers } from "../helpers";
import { useStore } from "../store";
import { useCookies } from "react-cookie";
import Statistic from "../components/Statistic";
import { Button, Display, Grid } from "@geist-ui/core";
import PageTitle from "../components/PageTitle";

export default function Dashboard() {
  const user = useStore((store) => store.user);
  const growth = useStore((store) => store.growth);
  const favourites = useStore((store) => store.favourites);
  const bookmarks = useStore((store) => store.bookmarks);
  const lists_on = useStore((store) => store.lists_on);
  const subscription = useStore((store) => store.subscription);

  const [cookies] = useCookies(["MastodonDomain"]);

  const shareWithFriends = () => {
    if (cookies && cookies["MastodonDomain"]) {
      window.open(
        "https://" +
          cookies["MastodonDomain"] +
          "/share?text=If%20you%20are%20curious%20to%20see%20how%20your%20Mastodon%20account%20performs%2C%20you%20should%20check%20out%3A%20https%3A%2F%2Fmetricdon.com",
        "_blank"
      );
    }
  };

  if (user === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageTitle
        title={"Daily Dashboard"}
        sub={"A quick glance at your most important metrics for today"}
      />

      <br />

      <Grid.Container justify="space-between">
        <Grid xs={24} md={6}>
          <Statistic
            data={Helpers.growthHistory(growth, "followers")}
            label={"FOLLOWERS"}
          />
        </Grid>
        <Grid xs={24} md={6}>
          <Statistic
            data={Helpers.growthHistory(growth, "following")}
            value={user.following_count}
            label={"FOLLOWING"}
          />
        </Grid>
        <Grid xs={24} md={6}>
          <Statistic
            data={Helpers.growthHistory(growth, "statuses")}
            value={user.statuses_count}
            label={"TOOTS"}
          />
        </Grid>
      </Grid.Container>
      <Grid.Container justify="space-between">
        <Grid xs={24} md={6}>
          <Statistic value={favourites.count} label={"FAVOURITES"} />
        </Grid>
        <Grid xs={24} md={6}>
          <Statistic value={bookmarks.count} label={"BOOKMARKS"} />
        </Grid>
        <Grid xs={24} md={6}>
          <Statistic value={lists_on.count} label={"ON LISTS"} />
        </Grid>
      </Grid.Container>

      {subscription && subscription.plan && subscription.plan === "free" && (
        <Display shadow>
          <div style={{ padding: 30, maxWidth: 1024 }}>
            <h3>Let us make this the perfect tool for you</h3>
            As an early adopter (you are one of our first hundred users), you
            can help us make this platform into a toolkit that is tailored to
            what you need and one that you love to use. Here is how you can have
            a major impact on where this platform is headed in the next few
            releases:
            <br />
            <br />
            {cookies && cookies["MastodonDomain"] && (
              <Button
                onClick={shareWithFriends}
                style={{ marginRight: 20 }}
                type={"success"}
                auto
              >
                Let your followers know
              </Button>
            )}
            <Button
              onClick={() => {
                window.$crisp.push([
                  "set",
                  "message:text",
                  [
                    "I would be interested in seeing the following kinds of data and/or reports: ",
                  ],
                ]);
                window.$crisp.push(["do", "chat:open"]);
              }}
              style={{ marginRight: 20 }}
              type={"success"}
            >
              Suggest an idea
            </Button>
          </div>
        </Display>
      )}
    </>
  );
}
