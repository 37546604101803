import PageTitle from "../components/PageTitle";
import LargeStatistic from "../components/LargeStatistic";
import FreePlanNotice from "../components/FreePlanNotice";
import { Helpers } from "../helpers";
import { useStore } from "../store";
export default function Growth() {
  const growth = useStore((store) => store.growth);

  return (
    <div>
      <PageTitle
        title="Growth Analysis"
        sub="A day-by-day analysis of your account's growth."
      />

      <FreePlanNotice
        limitLabel={
          "and shows only the last seven days, instead of a full year."
        }
        paddingBottom={40}
      />

      <h3>Followers</h3>
      <LargeStatistic
        data={Helpers.growthHistoryFull(growth, "followers")}
        label={"FOLLOWERS"}
      />

      <h3>People you Follow</h3>
      <LargeStatistic
        data={Helpers.growthHistoryFull(growth, "following")}
        label={"FOLLOWING"}
      />

      <h3>Toots you published</h3>
      <LargeStatistic
        data={Helpers.growthHistoryFull(growth, "statuses")}
        label={"STATUSES"}
      />
    </div>
  );
}
