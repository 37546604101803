import axios from "axios";
import JSONBig from "json-bigint";
import { useStore } from "./store";

export const API = {
  root: process.env.REACT_APP_METRICDON_LOCAL_API
    ? "http://localhost:8000"
    : "https://api.metricdon.com",
  s3: "https://metricdon-reports7921670e-1vv9slhc231rd.s3.eu-central-1.amazonaws.com",

  isStripeTest: () => {
    if (API.getStripeKey().indexOf("pk_test") >= 0) {
      return true;
    } else return false;
  },

  getStripeKey: () => {
    return process.env.REACT_APP_METRICDON_LOCAL_API
      ? "pk_test_51OhBHlCDbNc3LJFA1Bt7TiiASJ35zXsowZdSuKBjaowA3x2BmM9GPsvv9p2BmUFHXSU3SobO41SN3jczYVwdozGW00SWeKvrlj"
      : "pk_live_51OhBHlCDbNc3LJFAq0a1aYuiVPeKOORR6xifB1KTsWGUOZgmhEqnBQaYp6sVQEJd7V5QNxmaxgDenmgYMkjgR8U700lLPvHb02";
  },

  getPublicHash: () => {
    if (
      useStore.getState().public_hash &&
      useStore.getState().public_hash !== null
    ) {
      return useStore.getState().public_hash;
    } else {
      return "";
    }
  },

  getJWT: () => {
    if (useStore.getState().jwt && useStore.getState().jwt !== null) {
      return useStore.getState().jwt;
    } else {
      return "";
    }
  },

  getReport: async () => {
    let report = await axios({
      url: `${API.s3}/${API.getPublicHash()}.json?ac=${new Date().getTime()}`,
      method: "get",
      transformResponse: (response) => {
        return JSONBig().parse(response);
      }, // Needed for IDs, which are otherwise rounded
    });
    if (report) {
      return report.data;
    } else {
      return null;
    }
  },

  getAccount: async () => {
    try {
      let user = useStore.getState().user;
      if (user.id) {
        let response = await axios.get(`${API.root}/account`, {
          headers: {
            Authorization: `Bearer ${API.getJWT()}`,
          },
        });
        if (response.data.status) {
          return {
            messages: response.data.messages,
            subscription: response.data.subscription,
          };
        } else {
          return [];
        }
      }
    } catch (e) {}
  },

  getSubscriptionDetails: async () => {
    try {
      let user = useStore.getState().user;
      if (user.id) {
        let response = await axios.get(`${API.root}/account/subscription`, {
          headers: {
            Authorization: `Bearer ${API.getJWT()}`,
          },
        });
        if (response.data.status) {
          return {
            subscription: response.data.subscription,
          };
        } else {
          return {
            subscription: null,
          };
        }
      }
    } catch (e) {
      return {
        subscription: null,
      };
    }
  },

  cancelSubscription: async () => {
    try {
      let user = useStore.getState().user;
      if (user.id) {
        let response = await axios.get(
          `${API.root}/account/cancel_subscription`,
          {
            headers: {
              Authorization: `Bearer ${API.getJWT()}`,
            },
          }
        );
        if (response.data.status) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      return false;
    }
  },

  getVersion: async () => {
    let data = await axios.get(`${API.root}/version`);
    if (data) {
      return data.version;
    } else {
      return 0;
    }
  },

  getSetting: async (setting) => {
    try {
      let user = useStore.getState().user;
      if (user.id) {
        let response = await axios.get(
          `${API.root}/settings/account/${setting}`,
          {
            headers: {
              Authorization: `Bearer ${API.getJWT()}`,
            },
          }
        );
        if (response.status) {
          return response.data.data;
        } else {
          return null;
        }
      }
    } catch (e) {}
  },

  updateSetting: async (flag_name, flag_value) => {
    try {
      let user = useStore.getState().user;
      if (user.id) {
        await axios.post(
          `${API.root}/settings/account`,
          { flag_name: flag_name, flag_value: flag_value },
          {
            headers: {
              Authorization: `Bearer ${API.getJWT()}`,
            },
          }
        );
      }
    } catch (e) {}
  },

  removeAccount: async () => {
    try {
      let user = useStore.getState().user;
      if (user.id) {
        await axios.post(
          `${API.root}/settings/account/remove`,
          {},
          {
            headers: {
              Authorization: `Bearer ${API.getJWT()}`,
            },
          }
        );

        // Try this, just in case.
        const cookies = document.cookie.split("; ");
        cookies.forEach((cookie) => {
          const name = cookie.split("=").shift();
          document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/`;
        });
        window.localStorage.clear();
      }
    } catch (e) {}
  },
};
