import { Popover } from "@geist-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function AvatarMenu(props) {
  if (props.user === null) {
    return <div style={{ fontSize: 10, color: "#444" }}>Loading</div>;
  }

  const MenuRow = (props) => {
    const [isHover, setHover] = useState(false);
    return (
      <div
        style={{
          paddingTop: 6,
          paddingBottom: 6,
          pointer: "cursor",
          fontWeight: isHover ? "bold" : "normal",
          borderBottom: props.divider ? "1px dotted #ddd" : "none",
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <Link to={props.url}>{props.children}</Link>
      </div>
    );
  };

  const content = () => (
    <div
      style={{
        padding: "0 10px",
        marginLeft: 10,
        marginRight: 10,
        fontSize: 13,
        width: 160,
        userSelect: "none",
      }}
    >
      <MenuRow url="/subscription" divider>
        Plan & Payment
      </MenuRow>

      <MenuRow url="/settings" divider>
        Preferences
      </MenuRow>

      <MenuRow url="/logout">Log out</MenuRow>
    </div>
  );

  return (
    <Popover content={content} trigger="hover" style={{ userSelect: "none" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        <div
          style={{
            fontSize: 12,
            color: "#aaa",
            marginRight: 15,
            userSelect: "none",
          }}
        >
          {props.user.acct}
        </div>
        <img
          alt="profile"
          style={{ borderRadius: 30, width: 30, height: 30 }}
          src={props.user.avatar_static}
        ></img>
      </div>
    </Popover>
  );
}
