import PageTitle from "../components/PageTitle";

export default function Refunds() {
  return (
    <div>
      <PageTitle
        title="Refunds and Returns"
        sub="We want to make it as easy and as fair as possible, so please read this carefully"
      />
      <p>
        <strong>We can only offer refunds</strong> on our{" "}
        <strong>monthly subscription</strong>. We are always happy to refund the
        most recent payment, once you have decided to cancel the service and
        reach out to us about it. No need to explain why you canceled, we have
        no interest in pushing you to stay, or upsell you into anything else
        &mdash; all we want is that you get exactly what you need from us, for
        your internal billing processes (receipts, etc). We reserve the right{" "}
        <strong>to not refund</strong> the very first payment, if that was your
        only payment (people occasionally run a complete analysis, export all
        data and then request a refund to basically try and get the service for
        free). If the service didn't work, our servers were down, or you simply
        didn't get anything out of it, please, do reach out, we want to make it
        right, we will refund you, no problem at all. We are very flexible on
        the monthly plan, that's why it exists. If you have concerns if this is
        for you, before you upgrade, we do recommend strongly to use a monthly
        subscription to make sure you can stop at any time. Do not use the
        yearly or lifetime plan to try it out.
        <br />
        <br />
        <strong>
          We cannot offer refunds on our yearly or lifetime plans.
        </strong>
        <br />
        <br />
        We want to be fully transparent: when you sign up for a plan with us,
        the fully-featured PRO account means we are allocating real resources
        (which we are billed for) towards your account, which we can not recover
        after a refund is requested. In certain cases, we may be able to offer a
        refund, but we'll unfortunately need to determine this on a case-by-case
        basis. If this seems odd, please understand that there are people who
        sign up for a yearly plan, only to cancel at the last minute before it
        lapses onto the second year (to get a full year of service for free).
        That's not cool, and we're not going to let that happen. The yearly (as
        well as the lifetime) plan exists to reduce billing to a single payment
        per year (or ever), to help you budget for it more easily, make use of a
        15% discount (on yearly plans vs. monthly plans), as well as a much
        easier internal procurement process (typically), especially if you're
        purchasing towards the end of the year.
        <br />
        <br />
        We hope you'll understand, and we'd be curious to hear your thoughts and
        concerns about this at any time.
        <br />
        <br />
        <a href="mailto:support@metricdon.com">Please do reach out directly</a>,
        and we'll make sure to find a solution. We mean it.
      </p>
    </div>
  );
}
