import { Button } from "@geist-ui/core";
import { UserX } from "@geist-ui/icons";
import { useState } from "react";
import { API } from "../api";
import Loading from '../components/Loading';
import PageTitle from "../components/PageTitle";

export default function AccountRemoval() {

  const [isRemoving, setRemoving] = useState(false);

  const doIt = async () => {
    setRemoving(true);
    await API.removeAccount();
    setRemoving(false);
    alert("Okay, if everything worked out, your account data has been removed and you will be logged out after this. You can always come back. Thanks for giving it a try!")
    document.location.href = 'https://api.metricdon.com/bye';
  }

  return (
    <div>
      <PageTitle title={"You want to leave?"} sub={"It hurts, but we get it. Your data is your data!"}/>

      <div style={{ padding: 30, backgroundColor: "#ffe6e6", borderRadius: 30, width: 500, lineHeight: 2 }}>
        <strong>DANGER ZONE</strong><br /><br />
        Okay, take a another breath, then hit the button. There is no going back<sup style={{ color: "#888" }}>1</sup> and we'll try to explain this to the <a href="https://random.dog/">puppy</a> while you're gone. Who will also miss you. A lot.
        <br /><br />
        {!isRemoving &&
          <Button onClick={() => { doIt() }} auto icon={<UserX />} type="error" ghost>Delete my account</Button>
        }
        {isRemoving && <Loading />}
      </div>

      <div style={{ marginTop: 200, fontSize: 10, color: "#888" }}>
        <sup>1</sup> You can always sign up again. No hard feelings, come back anytime!
      </div>
    </div>
  )
}