import { useMediaQuery } from "@geist-ui/core";
import { useCookies } from "react-cookie";

export default function Supporter() {

  const isXS = useMediaQuery('xs')

  const [cookies,] = useCookies(['n0']);
  if (cookies && cookies["n0"]) {
    return (null)
  }

  return (
    <div style={{padding:isXS ? 20 : 10, textAlign: "center", fontSize:isXS ? 12: 10, color:"white", backgroundColor:"#353535", display: "block", width:"100%"}}>
      As a thank you for being one of our first 100 users, your account will remain completely free.
      &nbsp; <a style={{color:"orange"}} href="/free">Okay, Thanks!</a>
    </div>
  )
}