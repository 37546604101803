import { Toggle } from "@geist-ui/core";
import { useEffect, useState } from "react";
import { API } from "../api";
import Loading from "./Loading";

export default function SettingRow(props) {
  const [isSet, doSet] = useState(false)
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSetting = async () => {
      const data = await API.getSetting(props.flag)
      if (data != null) {
        doSet(data);
      }
      setLoading(false)
    };
    fetchSetting();
  }, [props.flag, doSet])

  const updateSetting = async (newSetting) => {
    setLoading(true)
    await API.updateSetting(props.flag, newSetting)
    setLoading(false)
  }

  const toggleSetting = async (e) => {
    if (typeof (e.target.checked) === "undefined") { // Click on label
      await updateSetting(!isSet);
      doSet(!isSet);
    } else { // Click on Toggle
      await updateSetting(e.target.checked);
    }
  }
  return (
    <div style={{ display: "flex", height: 40, flexDirection: "row", alignItems: "center", cursor: "pointer" }} onClick={toggleSetting}>
      {isLoading ? <Loading size={20} /> : <Toggle checked={isSet} onChange={toggleSetting} />}
      <div style={{ marginLeft: 15, marginTop: 4 }}>{props.title}</div>
    </div>
  )
}