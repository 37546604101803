import { create } from "zustand";

export const useStore = create((set) => ({
  jwt: null,
  public_hash: null,
  user: null,
  growth: null,
  meta: null,
  bookmarks: null,
  favourites: null,
  lists_on: null,
  followers: null,
  toots: [],
  system_messages: [],
  subscription: null,
  messages_last_updated: null,
  setPublicHash: (hash) =>
    set({
      public_hash: hash,
    }),
  setJWT: (jwt) => set({ jwt: jwt }),
  applyReportData: (report) =>
    set({
      user: report.user,
      growth: report.growth,
      meta: report.meta,
      bookmarks: report.bookmarks,
      favourites: report.favourites,
      lists_on: report.lists_on,
      followers: report.followers,
      toots: report.toots,
    }),
  setSystemMessages: (messages) =>
    set({
      system_messages: messages,
      messages_last_updated: new Date(),
    }),
  setSubscription: (subscription) =>
    set({
      subscription: subscription,
    }),
}));
